import { parse } from 'query-string';

import { getValueFromKey, Locale, Region } from '@eon-home/react-library';

import { StringOrNull } from '../types';
import { getAccessToken } from '.';
import { CMS_LANGS, LANGUAGE_QUERY_PARAM, REGION } from '../constants';

export const getBrowserLanguage = (): StringOrNull => {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = [
        'language',
        'browserLanguage',
        'systemLanguage',
        'userLanguage',
    ];

    let language = null;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (const languages of nav.languages) {
            language = languages;

            if (language && language.length) {
                break;
            }
        }
    }

    if (!language) {
        for (const langProps of browserLanguagePropertyKeys) {
            language = (nav as unknown as Record<string, string>)[langProps];

            if (language && language.length) {
                return language;
            }
        }
    }

    return language;
};

export const getUserLanguage = <T>(): keyof T => {
    const language = getBrowserLanguage() || Locale.EN;
    const browserLocale: string = language.split('-').shift()!.toUpperCase();

    return browserLocale as keyof T;
};

export const getDefaultLocale = (): Locale => {
    const browserLocale = getUserLanguage<typeof Locale>();
    return Locale[browserLocale] ? Locale[browserLocale] : Locale.EN;
};

export const getDefaultRegion = (): Region => {
    const browserLocale = getUserLanguage<typeof Region>();
    return Region[browserLocale] ? Region[browserLocale] : Region.GB;
};

export const getRegion = (): Region => {
    const regions = Object.keys(CMS_LANGS);

    if (!getAccessToken()) {
        const queryString = parse(location.search);

        let regionAsParam = getValueFromKey(
            queryString,
            LANGUAGE_QUERY_PARAM,
        ) as string;

        // Use both options for Swedish
        if (regionAsParam === 'sv') {
            regionAsParam = 'se';
        }

        // Because CMS_LANGS includes this key
        if (regionAsParam === 'en') {
            regionAsParam = 'gb';
        }

        if (regionAsParam && regions.includes(regionAsParam.toUpperCase())) {
            const region = regionAsParam.toUpperCase();

            localStorage.setItem(REGION, region);

            return region as Region;
        }
    }

    const savedRegion = localStorage.getItem(REGION);

    if (savedRegion && regions.includes(savedRegion as any)) {
        return savedRegion as Region;
    }

    const defaultRegion = getDefaultRegion();

    localStorage.setItem(REGION, defaultRegion);

    return defaultRegion;
};

export const isItalianUser = () => getRegion() === Region.IT;

export const isGBUser = () => getRegion() === Region.GB;

export const isSwedishUser = () => getRegion() === Region.SE;

export const isGermanUser = () => getRegion() === Region.DE;

export const isHungarianUser = () => getRegion() === Region.HU;
