import {
    HvacDeviceModifyModelOneOfCommandEnum,
    SmartHomeDeviceModifyModelOneOfCommandEnum,
} from '@swagger-http';

import { LiveDataActionTypes } from '@store/enums';
import {
    LiveDataState,
    LiveDataAction,
    LiveEventDeviceModel,
} from '@store/types';

export const initialState: LiveDataState = {
    telemetries: {
        emobility: {
            wallbox: {
                commandError: false, // will be true if e.g. virta cannot be reached for charging
                loading: true,
                isFinishing: false,
                evStateLoading: true,
            },
            electricCar: {
                commandError: false, // will be true if e.g. enode cannot be reached for charging
                loading: true,
                evStateLoading: true,
            },
        },
        pvbattery: {
            error: false,
            loading: true,
            telemetriesReceived: 0,
        },
        gcp: {
            error: false,
            loading: true,
            telemetriesReceived: 0,
        },
        dhw: {},
        compressor: {},
        notifications: {
            notifications: [],
            loading: false,
        },
        gatewayStatuses: {},
    },
    devices: {},
};

export const mergeData = (
    oldState: Record<string, LiveEventDeviceModel>,
    newState: LiveEventDeviceModel,
): Record<string, LiveEventDeviceModel> => {
    const { deviceId }: any = newState;
    const mergedState: Record<string, LiveEventDeviceModel> = { ...oldState };

    if (mergedState[deviceId]) {
        mergedState[deviceId] = {
            ...mergedState[deviceId],
            ...newState,
        };
    } else {
        mergedState[deviceId] = {
            ...newState,
        };
    }

    return mergedState;
};

export const modifyState = (
    state: LiveDataState,
    action: LiveDataAction,
    key: string,
): LiveDataState => {
    const modifiedDevice = {
        ...state.devices[action.payload.deviceId],
        [key]: action.payload.value,
    };

    return {
        ...state,
        devices: {
            ...state.devices,
            [action.payload.deviceId]: modifiedDevice,
        },
    };
};

export default function reducer(
    state: LiveDataState = initialState,
    action: LiveDataAction,
): LiveDataState {
    switch (action.type) {
        case LiveDataActionTypes.WALLBOX_TELEMETRIES:
            return {
                ...state,
                telemetries: {
                    ...state.telemetries,
                    emobility: {
                        ...state.telemetries.emobility,
                        wallbox: {
                            ...state.telemetries.emobility.wallbox,
                            ...action.payload,
                        },
                    },
                },
            };
        case LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES:
            return {
                ...state,
                telemetries: {
                    ...state.telemetries,
                    emobility: {
                        ...state.telemetries.emobility,
                        electricCar: {
                            ...state.telemetries.emobility.electricCar,
                            ...action.payload,
                        },
                    },
                },
            };
        case LiveDataActionTypes.PVBATTERY_TELEMETRIES:
            return {
                ...state,
                telemetries: {
                    ...state.telemetries,
                    pvbattery: {
                        ...state.telemetries.pvbattery,
                        ...action.payload,
                        // prettier-ignore
                        telemetriesReceived: state.telemetries.pvbattery.telemetriesReceived + 1,
                    },
                },
            };
        case LiveDataActionTypes.GCP_TELEMETRIES:
            return {
                ...state,
                telemetries: {
                    ...state.telemetries,
                    gcp: {
                        ...state.telemetries.gcp,
                        ...action.payload,
                        loading: false,
                        // prettier-ignore
                        telemetriesReceived: state.telemetries.gcp.telemetriesReceived + 1,
                    },
                },
            };
        case LiveDataActionTypes.SMART_LIGHTS_EVENT:
        case LiveDataActionTypes.SMART_PLUGS_EVENT:
        case LiveDataActionTypes.HEATING_COOLING_EVENT:
        case LiveDataActionTypes.SMART_PLUGS_TELEMETRIES:
        case LiveDataActionTypes.HEATING_COOLING_TELEMETRIES:
            return {
                ...state,
                devices: {
                    ...mergeData(state.devices, action.payload),
                },
            };
        case LiveDataActionTypes.UPDATE_SMART_LIGHT:
            switch (action.payload.command) {
                case SmartHomeDeviceModifyModelOneOfCommandEnum.Switch:
                    return modifyState(state, action, 'state');

                case SmartHomeDeviceModifyModelOneOfCommandEnum.DimLevel:
                    return modifyState(state, action, 'dimLevel');

                default:
                    return state;
            }
        case LiveDataActionTypes.UPDATE_SMART_PLUG:
            return modifyState(state, action, 'state');
        case LiveDataActionTypes.UPDATE_HEATING_COOLING:
            switch (action.payload.command) {
                case HvacDeviceModifyModelOneOfCommandEnum.Temperatures:
                    return {
                        ...state,
                        devices: {
                            ...state.devices,
                            [action.payload.deviceId]: {
                                ...state.devices[action.payload.deviceId],
                                metrics: {
                                    temperature: {
                                        celsius: action.payload.value,
                                        fahrenheit: action.payload.value,
                                    },
                                },
                            },
                        },
                    };
                case HvacDeviceModifyModelOneOfCommandEnum.Power:
                    return modifyState(state, action, 'power');
                case HvacDeviceModifyModelOneOfCommandEnum.FanSpeeds:
                    return modifyState(state, action, 'fanSpeed');
                case HvacDeviceModifyModelOneOfCommandEnum.Mode:
                    return modifyState(state, action, 'mode');
                case HvacDeviceModifyModelOneOfCommandEnum.Swings:
                    return modifyState(state, action, 'swing');
                default:
                    return state;
            }

        case LiveDataActionTypes.RESET_SMART_DEVICES:
            return {
                ...state,
                devices: {},
            };

        case LiveDataActionTypes.GATEWAY_STATUSES:
            return {
                ...state,
                telemetries: {
                    ...state.telemetries,
                    gatewayStatuses: action.payload,
                },
            };
        default:
            return state;
    }
}
