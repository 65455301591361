import { useMemo } from 'react';

import { Moment } from '@tools/utils';
import { useHasElectricCar, useHasWallbox } from '@store/selectors';

export const useHasEmobility = (): boolean => {
    const hasElectricCar = useHasElectricCar();
    const hasWallbox = useHasWallbox();

    return hasElectricCar || hasWallbox;
};

export const useHasEmobilityTokenExpired = (tokenExpiryDate?: string) => {
    return useMemo(() => {
        if (!tokenExpiryDate) {
            return false;
        }

        return Moment(tokenExpiryDate).isSameOrBefore(Moment());
    }, [tokenExpiryDate]);
};
