import { InsightsActionTypes } from '@store/enums';
import { Insights, InsightsAction } from '@store/types';

const getInitialBranchState = <T>(data: T) => ({
    data,
    error: false,
    loading: true,
});

export const initialState: Insights = {
    co2: {
        ...getInitialBranchState({
            avoided: null,
            exported: null,
            imported: null,
            generation: null,
        }),
        expiration: -1,
    },
    pv: {
        balance: getInitialBranchState(null),
        comparison: getInitialBranchState({
            day: null,
            week: null,
            month: null,
        }),
        forecast: getInitialBranchState(null),
        highlights: getInitialBranchState(null),
    },
    heating: {
        home: getInitialBranchState(null),
        range: getInitialBranchState(null),
        conditions: getInitialBranchState(null),
        highlights: getInitialBranchState(null),
    },
    emobility: {
        lastChargingSession: getInitialBranchState(null),
    },
    smartMeter: {
        comparison: getInitialBranchState({
            week: null,
            month: null,
            year: null,
        }),
        disaggregation: getInitialBranchState(null),
        energyProfile: getInitialBranchState(null),
        highlights: getInitialBranchState(null),
        similarHomes: getInitialBranchState(null),
    },
};

export default function reducer(
    state: Insights = initialState,
    action: InsightsAction,
): Insights {
    switch (action.type) {
        case InsightsActionTypes.SET_CO2_DATA:
        case InsightsActionTypes.SET_CO2_ERROR:
        case InsightsActionTypes.SET_CO2_LOADING:
        case InsightsActionTypes.SET_CO2_EXPIRATION:
            return {
                ...state,
                co2: {
                    ...state.co2,
                    ...action.payload,
                },
            };
        case InsightsActionTypes.SET_PV_INSIGHTS_DATA:
            return {
                ...state,
                pv: {
                    balance: {
                        ...state.pv.balance,
                        ...action.payload.balance,
                    },
                    comparison: {
                        ...state.pv.comparison,
                        ...action.payload.comparison,
                    },
                    forecast: {
                        ...state.pv.forecast,
                        ...action.payload.forecast,
                    },
                    highlights: {
                        ...state.pv.highlights,
                        ...action.payload.highlights,
                    },
                },
            };
        case InsightsActionTypes.SET_PV_INSIGHTS_ERROR:
        case InsightsActionTypes.SET_PV_INSIGHTS_LOADING:
            return {
                ...state,
                pv: {
                    balance: {
                        ...state.pv.balance,
                        ...action.payload,
                    },
                    comparison: {
                        ...state.pv.comparison,
                        ...action.payload,
                    },
                    forecast: {
                        ...state.pv.forecast,
                        ...action.payload,
                    },
                    highlights: {
                        ...state.pv.highlights,
                        ...action.payload,
                    },
                },
            };

        case InsightsActionTypes.SET_SMART_METER_INSIGHTS_DATA:
            return {
                ...state,
                smartMeter: {
                    comparison: {
                        ...state.smartMeter.comparison,
                        ...action.payload.comparison,
                    },
                    disaggregation: {
                        ...state.smartMeter.disaggregation,
                        ...action.payload.disaggregation,
                    },
                    energyProfile: {
                        ...state.smartMeter.energyProfile,
                        ...action.payload.energyProfile,
                    },
                    highlights: {
                        ...state.smartMeter.highlights,
                        ...action.payload.highlights,
                    },
                    similarHomes: {
                        ...state.smartMeter.similarHomes,
                        ...action.payload.similarHomes,
                    },
                },
            };
        case InsightsActionTypes.SET_SMART_METER_INSIGHTS_ERROR:
        case InsightsActionTypes.SET_SMART_METER_INSIGHTS_LOADING:
            return {
                ...state,
                smartMeter: {
                    comparison: {
                        ...state.smartMeter.comparison,
                        ...action.payload,
                    },
                    disaggregation: {
                        ...state.smartMeter.disaggregation,
                        ...action.payload,
                    },
                    energyProfile: {
                        ...state.smartMeter.energyProfile,
                        ...action.payload,
                    },
                    highlights: {
                        ...state.smartMeter.highlights,
                        ...action.payload,
                    },
                    similarHomes: {
                        ...state.smartMeter.similarHomes,
                        ...action.payload,
                    },
                },
            };

        case InsightsActionTypes.SET_HEATING_INSIGHTS_DATA:
            return {
                ...state,
                heating: {
                    home: {
                        ...state.heating.home,
                        ...action.payload.home,
                    },
                    range: {
                        ...state.heating.range,
                        ...action.payload.range,
                    },
                    conditions: {
                        ...state.heating.conditions,
                        ...action.payload.conditions,
                    },
                    highlights: {
                        ...state.heating.highlights,
                        ...action.payload.highlights,
                    },
                },
            };
        case InsightsActionTypes.SET_HEATING_INSIGHTS_ERROR:
        case InsightsActionTypes.SET_HEATING_INSIGHTS_LOADING:
            return {
                ...state,
                heating: {
                    home: {
                        ...state.heating.home,
                        ...action.payload,
                    },
                    range: {
                        ...state.heating.range,
                        ...action.payload,
                    },
                    conditions: {
                        ...state.heating.conditions,
                        ...action.payload,
                    },
                    highlights: {
                        ...state.heating.highlights,
                        ...action.payload,
                    },
                },
            };
        case InsightsActionTypes.SET_EMOBILITY_LAST_SESSION_DATA:
        case InsightsActionTypes.SET_EMOBILITY_LAST_SESSION_ERROR:
        case InsightsActionTypes.SET_EMOBILITY_LAST_SESSION_LOADING:
            return {
                ...state,
                emobility: {
                    ...state.emobility,
                    lastChargingSession: {
                        ...state.emobility.lastChargingSession,
                        ...action.payload,
                    },
                },
            };

        default:
            return state;
    }
}
