import { Dispatch } from 'redux';

import {
    PvBatteryApi,
    SolarCloudApi,
    ResponseError,
    SolarCloudBalanceV2ResponseModel,
    PvBatteryHistoryAggregationResponseModel as PvAggregationModel,
} from '@swagger-http';

import { Scope } from '@tools/enums';
import { HistoricalDataAction } from '@store/types';
import {
    HistoricalInterval,
    LiveDataActionTypes,
    HistoricalDataActionTypes,
} from '@store/enums';
import {
    Moment,
    handleError,
    checkForScopes,
    createRequestConfiguration,
} from '@tools/utils';

export const setHistoricalData = (
    type: HistoricalDataActionTypes,
    payload: any,
): HistoricalDataAction => ({
    type,
    payload,
});

export const getSolarBalanceV2 =
    () =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_GCP_READ])) {
            return Promise.resolve();
        }

        return new SolarCloudApi(createRequestConfiguration())
            .solarCloudGetSolarCloudBalanceV2()
            .then((response: SolarCloudBalanceV2ResponseModel) => {
                dispatch({
                    type: LiveDataActionTypes.GCP_TELEMETRIES,
                    payload: {
                        solarCloudBalanceV2: response.solarCloudBalanceTotal,
                        solarCloudBalanceTimestampV2: response.lastUpdate,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error fetching solar balance v2: ');
            });
    };

export const getDiff = (data: PvAggregationModel[]) => {
    if (data.length === 0) {
        return 0;
    }

    const last = data[data.length - 1].values?.inverterEnergyOut || 0;
    const first = data[0].values?.inverterEnergyOut || 0;

    return Math.max(0, last - first || 0);
};

export const getPvGeneration = async (
    startDate: string,
    endDate: string,
    timezone: string,
    interval: string = HistoricalInterval.FIFTEEN_MINUTES,
): Promise<number> => {
    if (!checkForScopes([Scope.ENERGYDEVICES_PVB_READ])) {
        return Promise.resolve(0);
    }

    const pvbApi = new PvBatteryApi(createRequestConfiguration());

    const now = Moment().tz(timezone);
    const yesterday = now.clone().subtract(1, 'day');
    const yesterdayStart = yesterday.clone().startOf('day');

    try {
        const response = await pvbApi.pvBatteryGetHistoryAggregated({
            fromDate: startDate,
            toDate: endDate,
            interval,
        });

        if (interval === HistoricalInterval.FIFTEEN_MINUTES) {
            if (response.length === 3) {
                return Math.max(
                    0,
                    response[response.length - 1].values?.inverterPower || 0,
                );
            }

            return getDiff(
                response.filter((item) =>
                    Moment(item.timestamp).isBetween(yesterdayStart, yesterday),
                ),
            );
        }

        const data = response
            .slice(-2)
            .filter(
                (item: PvAggregationModel) =>
                    item.values?.inverterEnergyOut !== null,
            );

        if (data.length === 0) {
            return 0;
        }

        return getDiff(data);
    } catch (e) {
        Promise.reject(
            await handleError(e, 'Error when getting generation data:'),
        );

        return 0;
    }
};
