import * as React from 'react';
import loadable, { LoadableComponent } from '@loadable/component';

import { Loader } from '@eon-home/react-library';

const load = (
    path: string,
    isContainer: boolean = false,
): LoadableComponent<any> =>
    loadable(
        () => import(`../${isContainer ? 'containers' : 'common'}/${path}`),
        { fallback: <Loader /> },
    );

export const ActionRequired = load('action-required/page', true);
export const AccountDeleted = load('account-deleted', true);
export const ActivationPopup = load('activation-popup');
export const Auth = load('auth/handler', true);
export const CookieConsent = load('cookie-consent');
export const ConnectWallbox = load('connect/wallbox', true);
export const ConnectSmartDevices = load('connect/smart-devices', true);
export const ConnectElectricCar = load('connect/electric-car', true);
export const ConnectSmartMeter = load('connect/smart-meter', true);
export const DataStore = load('data-store');
export const Devices = load('devices/page', true);
export const ElectricCar = load('emobility/page/electric-car', true);
export const Wallbox = load('emobility/page/wallbox', true);
export const EmobilityChargingHistory = load(
    'emobility/charging-history/page',
    true,
);
export const Energy = load('energy/page', true);
export const Header = load('header/desktop');
export const MobileHeader = load('header/mobile');
export const Home = load('energy-flow/main', true);
export const HomeMobile = load('energy-flow/mobile', true);
export const IncidentMessage = load('incident-message');
export const InstallationStatus = load('status-widget/installation');
export const Insights = load('insights/page', true);
export const Messaging = load('messaging');
export const Menu = load('menu');
export const MobileLanding = load('mobile-landing-page');
export const MobileRegistration = load('registration/mobile', true);
export const More = load('more/page', true);
export const NotFound = load('not-found');
export const OAuth = load('auth/oauth', true);
export const Overview = load('overview');
export const PvInstall = load('energy/pvbattery-installation', true);
export const GridXInstall = load('energy/gridx-installation', true);
export const QualtricsPrompt = load('qualtrics');
export const Registration = load('registration/signup', true);
export const RelinkSmartHome = load('status-widget/relink-smarthome');
export const Sales = load('sales', true);
export const SessionManager = load('session-manager');
export const SmartMeter = load('meter/page', true);
export const StepUpRegistration = load('registration/step-up', true);
export const Survey = load('survey/page', true);
export const UpdateAvailable = load('update-available');
export const VirtaFirmwareUpdate = load('emobility/firmware-update', true);
export const EmobilityTamperNotification = load(
    'emobility/tamper-notification',
    true,
);
