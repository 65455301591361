export const enum EmobilityActionTypes {
    WALLBOX_SET_ERROR = 'WALLBOX/SET_ERROR',
    WALLBOX_SET_LOADING = 'WALLBOX/SET_LOADING',
    WALLBOX_SET_DEVICE_DATA = 'WALLBOX/SET_DEVICE_DATA',
    WALLBOX_RESET_STATE = 'WALLBOX/RESET_STATE',
    WALLBOX_GET_CHARGING_HISTORY = 'WALLBOX/GET_CHARGING_HISTORY',
    WALLBOX_GET_CHARGING_HISTORY_ERROR = 'WALLBOX/GET_CHARGING_HISTORY_ERROR',
    WALLBOX_GET_CHARGING_HISTORY_LOADING = 'WALLBOX/GET_CHARGING_HISTORY_LOADING',
    WALLBOX_SET_CHARGING_MODE_ERROR = 'WALLBOX/SET_CHARGING_MODE_ERROR',
    WALLBOX_SET_CHARGING_MODES_DATA = 'WALLBOX/SET_CHARGING_MODES_DATA',
    WALLBOX_SET_CHARGING_MODES_ERROR = 'WALLBOX/SET_CHARGING_MODES_ERROR',
    WALLBOX_SET_CHARGING_MODES_LOADING = 'WALLBOX/SET_CHARGING_MODES_LOADING',
    WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS = 'WALLBOX/SET_FIRMWARE_UPDATE_SETTINGS',
    WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS_ERROR = 'WALLBOX/SET_FIRMWARE_UPDATE_SETTINGS_ERROR',

    ELECTRIC_CAR_SET_ERROR = 'ELECTRIC_CAR/SET_ERROR',
    ELECTRIC_CAR_SET_LOADING = 'ELECTRIC_CAR/SET_LOADING',
    ELECTRIC_CAR_SET_DEVICE_DATA = 'ELECTRIC_CAR/SET_DEVICE_DATA',
    ELECTRIC_CAR_RESET_STATE = 'ELECTRIC_CAR/RESET_STATE',
    ELECTRIC_CAR_SET_ADDRESS = 'ELECTRIC_CAR/SET_ADDRESS',
    ELECTRIC_CAR_SET_CHARGING_MODE_ERROR = 'ELECTRIC_CAR/SET_CHARGING_MODE_ERROR',
    ELECTRIC_CAR_SET_CHARGING_MODES_DATA = 'ELECTRIC_CAR/SET_CHARGING_MODES_DATA',
    ELECTRIC_CAR_SET_CHARGING_MODES_ERROR = 'ELECTRIC_CAR/SET_CHARGING_MODES_ERROR',
    ELECTRIC_CAR_SET_CHARGING_MODES_LOADING = 'ELECTRIC_CAR/SET_CHARGING_MODES_LOADING',
}

export const enum EmobilityTypes {
    WALLBOX = 'wallbox',
    ELECTRIC_CAR = 'electric-car',
}

export const enum EmobilityVendors {
    VIRTA = 'virta',
    ENODE = 'enode',
}

export const EMOBILITY_BRANDS: Record<EmobilityVendors, string> = {
    [EmobilityVendors.ENODE]: 'E.ON Car connect',
    [EmobilityVendors.VIRTA]: 'Virta',
};
