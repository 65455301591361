export const enum InsightsActionTypes {
    SET_CO2_DATA = 'SET_CO2_DATA',
    SET_CO2_ERROR = 'SET_CO2_ERROR',
    SET_CO2_LOADING = 'SET_CO2_LOADING',
    SET_CO2_EXPIRATION = 'SET_CO2_EXPIRATION',
    SET_PV_INSIGHTS_DATA = 'SET_PV_INSIGHTS_DATA',
    SET_PV_INSIGHTS_ERROR = 'SET_PV_INSIGHTS_ERROR',
    SET_PV_INSIGHTS_LOADING = 'SET_PV_INSIGHTS_LOADING',
    SET_HEATING_INSIGHTS_DATA = 'SET_HEATING_INSIGHTS_DATA',
    SET_HEATING_INSIGHTS_ERROR = 'SET_HEATING_INSIGHTS_ERROR',
    SET_HEATING_INSIGHTS_LOADING = 'SET_HEATING_INSIGHTS_LOADING',
    SET_SMART_METER_INSIGHTS_DATA = 'SET_SMART_METER_INSIGHTS_DATA',
    SET_SMART_METER_INSIGHTS_ERROR = 'SET_SMART_METER_INSIGHTS_ERROR',
    SET_SMART_METER_INSIGHTS_LOADING = 'SET_SMART_METER_INSIGHTS_LOADING',
    SET_EMOBILITY_LAST_SESSION_DATA = 'SET_EMOBILITY_LAST_SESSION_DATA',
    SET_EMOBILITY_LAST_SESSION_ERROR = 'SET_EMOBILITY_LAST_SESSION_ERROR',
    SET_EMOBILITY_LAST_SESSION_LOADING = 'SET_EMOBILITY_LAST_SESSION_LOADING',
}
