import { io } from 'socket.io-client';
import { Dispatch } from 'redux';
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';
import 'eventsource/example/eventsource-polyfill';

import {
    isDefined,
    isAdminPortal,
    getValueFromKey,
} from '@eon-home/react-library';

import { SmartHomeDeviceModelTypeEnum } from '@swagger-http';
import {
    GcpResponseModel,
    HvacResponseModel,
    EventsResponseModel,
    WallBoxResponseModel,
    SmartHomeResponseModel,
    PvBatteryItemsResponseModel,
} from '@swagger-sse/api';

import { EventsFactory } from '@store/actions';
import { SSE_URL_DEFAULT } from '@tools/constants';
import {
    BatteryStatus,
    LiveDataEventTypes,
    LiveDataActionTypes,
    MessageTypeEnumExtended,
    NotificationsActionTypes,
} from '@store/enums';
import {
    WebSocketData,
    LiveEventData,
    LiveDataEvent,
    LiveDataAction,
    EventSourceData,
    NotificationsResponseModel,
} from '@store/types';
import {
    debug,
    toJSON,
    toBase64,
    handleError,
    isSwedishUser,
    getAccessToken,
} from '@tools/utils';

import MessageTypeEnum = EventsResponseModel.MessageTypeEnum;

export let sseSource: EventSource | undefined;
export let webSocket: W3CWebSocket | undefined;

export const getBatteryState = (batteryPower: number): BatteryStatus => {
    if (batteryPower === 0) {
        return BatteryStatus.Idle;
    } else if (batteryPower > 0) {
        return BatteryStatus.Charging;
    } else {
        return BatteryStatus.Discharging;
    }
};

export const processSolarPanelLiveData = <T>(
    data: T,
    dispatch: Dispatch,
): LiveDataAction => {
    const batteryPower = getValueFromKey(
        data,
        'data.battery.power.total',
    ) as number;

    return dispatch({
        type: LiveDataActionTypes.PVBATTERY_TELEMETRIES,
        payload: {
            // PV
            pv2home: getValueFromKey(data, 'data.PV.energy.PV2home.total'),
            pvGeneration: getValueFromKey(data, 'data.PV.power.total'),

            // Battery
            battery2home: getValueFromKey(
                data,
                'data.battery.energy.battery2home.total',
            ),
            home2battery: getValueFromKey(
                data,
                'data.battery.energy.home2battery.total',
            ),
            batteryCharge: getValueFromKey(
                data,
                'data.battery.energy.stateOfCharge',
            ),
            batteryPower,
            batteryStatus: getBatteryState(batteryPower),
            batteryCurrent: getValueFromKey(data, 'data.battery.current.total'),

            // Inverter
            inverterPower: getValueFromKey(data, 'data.inverter.power.total'),
            inverterEnergyIn: getValueFromKey(data, 'data.inverter.energy.in'),
            inverterEnergyOut: getValueFromKey(
                data,
                'data.inverter.energy.out',
            ),

            // Meta
            timestamp: getValueFromKey(data, 'creationTime'),
            error: false,
            loading: false,

            // Hide the empty Energy Flow state
            hasLiveData: true,
        },
    });
};

export const processMeterLiveData = <T>(
    data: T,
    dispatch: Dispatch,
): LiveDataAction =>
    dispatch({
        type: LiveDataActionTypes.GCP_TELEMETRIES,
        payload: {
            // Current
            currentL1: getValueFromKey(data, 'data.current.L1'),
            currentL2: getValueFromKey(data, 'data.current.L2'),
            currentL3: getValueFromKey(data, 'data.current.L3'),

            // Energy
            grid2home: getValueFromKey(data, 'data.energy.grid2home.total'),
            home2grid: getValueFromKey(data, 'data.energy.home2grid.total'),
            energyConsumption: getValueFromKey(
                data,
                'data.energy.consumption.total',
            ),
            energySelfConsumption: getValueFromKey(
                data,
                'data.energy.selfConsumption.total',
            ),

            // Solar Cloud
            solarCloudBalance: getValueFromKey(
                data,
                'data.energy.solarCloud.balance',
            ),
            solarCloudBalanceTimestamp: getValueFromKey(
                data,
                'data.energy.solarCloud.readingTimestamp',
            ),

            // Power
            power: getValueFromKey(data, 'data.power.total'),
            powerConsumption: getValueFromKey(
                data,
                'data.power.consumption.total',
            ),
            powerSelfConsumption: getValueFromKey(
                data,
                'data.power.selfConsumption.total',
            ),

            // Meta
            error: false,
            loading: false,
            timestamp: getValueFromKey(data, 'creationTime'),
        },
    });

export const processSmartHomeLiveData = <T extends SmartHomeResponseModel>(
    data: T | void,
    dispatch: Dispatch,
): LiveDataAction => {
    if (isSwedishUser()) {
        return dispatch({
            type: LiveDataActionTypes.SMART_PLUGS_TELEMETRIES,
            payload: {},
        });
    }

    if (
        data &&
        data.deviceType ===
            (SmartHomeDeviceModelTypeEnum.Socket as unknown as SmartHomeResponseModel.DeviceTypeEnum)
    ) {
        const power = getValueFromKey(data, 'data.power.total');
        const energy = getValueFromKey(data, 'data.energy.total');
        const deviceId = getValueFromKey(data, 'deviceId');
        const timestamp = getValueFromKey(data, 'creationTime');

        if (power) {
            return dispatch({
                type: LiveDataActionTypes.SMART_PLUGS_TELEMETRIES,
                payload: {
                    power,
                    deviceId,
                    timestamp,
                },
            });
        }

        if (energy) {
            return dispatch({
                type: LiveDataActionTypes.SMART_PLUGS_TELEMETRIES,
                payload: {
                    energy,
                    deviceId,
                    timestamp,
                },
            });
        }
    }

    return dispatch({
        type: LiveDataActionTypes.SMART_PLUGS_TELEMETRIES,
        payload: {},
    });
};

export const processHeatingCoolingLiveData = <T>(
    data: T,
    dispatch: Dispatch,
): LiveDataAction => {
    const temperature: Record<string, number> = {};

    if (isDefined(data, 'data.temperature.celsius')) {
        temperature.celsius = getValueFromKey(
            data,
            'data.temperature.celsius',
        ) as number;
    }

    if (isDefined(data, 'data.temperature.fahrenheit')) {
        temperature.fahrenheit = getValueFromKey(
            data,
            'data.temperature.fahrenheit',
        ) as number;
    }

    return dispatch({
        type: LiveDataActionTypes.HEATING_COOLING_TELEMETRIES,
        payload: {
            active: true,
            deviceId: getValueFromKey(data, 'deviceId'),
            timestamp: getValueFromKey(data, 'creationTime'),
            temperature,
        },
    });
};

export const processWallboxLiveData = <T>(
    data: T,
    dispatch: Dispatch,
): LiveDataAction =>
    dispatch({
        type: LiveDataActionTypes.WALLBOX_TELEMETRIES,
        payload: {
            power: getValueFromKey(data, 'data.power.home2EV.session'),
            timestamp: getValueFromKey(data, 'creationTime'),
            consumption: getValueFromKey(data, 'data.energy.home2EV.session'),
            telemetrySessionId: getValueFromKey(data, 'data.chargingSessionID'),
            telemetryTimestamp: getValueFromKey(data, 'creationTime'),
        },
    });

export const processElectricCarLiveData = <T>(
    data: T,
    dispatch: Dispatch,
): LiveDataAction =>
    dispatch({
        type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
        payload: {
            isElectricCarOnline: true, // TODO as soon as BE is ready: getValueFromKey(data, 'data.isOnline'),
            isElectricCarHome: getValueFromKey(data, 'data.isHome'),
            evStateLoading: false,
            isElectricCarPluggedIn: getValueFromKey(data, 'data.isPluggedIn'),
            isElectricCarCharging: getValueFromKey(data, 'data.isCharging'),
            electricCarRange: getValueFromKey(data, 'data.range'),
            electricCarBatteryLevel: getValueFromKey(data, 'data.batteryLevel'),
            timestamp: getValueFromKey(data, 'creationTime'),
            telemetryTimestamp: getValueFromKey(data, 'creationTime'),
        },
    });

export const processAlarmsLiveData = <T extends NotificationsResponseModel>(
    data: T | void,
    dispatch: Dispatch,
): LiveDataAction => {
    if (!data) {
        return dispatch({
            type: LiveDataActionTypes.EVENT_NOTIFICATION,
            payload: {},
        });
    }

    if (data.messageType === MessageTypeEnum.Alert) {
        const liveDataEvent: LiveDataEvent = new EventsFactory().getEvent(data);
        const action = liveDataEvent.getAction();

        if (action.payload) {
            return dispatch(action);
        }
    }

    if (data.messageType === MessageTypeEnum.Notification) {
        return dispatch({
            type: LiveDataActionTypes.EVENT_NOTIFICATION,
            payload: {
                id: getValueFromKey(data, 'id'),
                code: getValueFromKey(data, 'messageSubType'),
                date: getValueFromKey(data, 'creationTime'),
                read: getValueFromKey(data, 'ack'),
                type: getValueFromKey(data, 'messageType'),
                variables: getValueFromKey(data, 'data.variables'),
            },
        });
    }

    if (data.messageType === MessageTypeEnumExtended.EVSE_FIRMWARE) {
        return dispatch({
            type: NotificationsActionTypes.SET_EMOB_FIRMWARE_NOTIFICATION,
            payload: getValueFromKey(data, 'data'),
        });
    }

    if (
        data.messageType === MessageTypeEnumExtended.EVSE_SECURITY_NOTIFICATION
    ) {
        return dispatch({
            type: NotificationsActionTypes.UPDATE_TAMPER_NOTIFICATION,
            payload: getValueFromKey(data, 'data'),
        });
    }

    return dispatch({
        type: LiveDataActionTypes.EVENT_NOTIFICATION,
        payload: {},
    });
};

export const processGatewayLiveData = <T>(
    payload: T,
    dispatch: Dispatch,
): LiveDataAction =>
    dispatch({
        type: LiveDataActionTypes.GATEWAY_STATUSES,
        payload,
    });

export const processLiveEvent = (
    eventType: LiveDataEventTypes,
    callback: (data: EventSourceData<LiveEventData>) => LiveDataAction | void,
): void => {
    if (!sseSource) {
        return;
    }

    sseSource.addEventListener(eventType, callback);
};

export const connectSSE = (
    token: string,
    dispatch: Dispatch,
): EventSource | void => {
    if (window.Cypress) {
        return;
    }

    const initProps = {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: false,
    };

    try {
        sseSource = new window.EventSourcePolyfill(SSE_URL_DEFAULT, initProps);
    } catch (e) {
        handleError(e, 'Failed connecting to SSE stream:');
    }

    processLiveEvent(LiveDataEventTypes.PVBATTERY_TELEMETRIES, ({ data }) =>
        processSolarPanelLiveData(
            toJSON<PvBatteryItemsResponseModel>(data),
            dispatch,
        ),
    );

    processLiveEvent(LiveDataEventTypes.GCP_TELEMETRIES, ({ data }) =>
        processMeterLiveData(toJSON<GcpResponseModel>(data), dispatch),
    );

    processLiveEvent(LiveDataEventTypes.SMARTHOME_TELEMETRIES, ({ data }) =>
        processSmartHomeLiveData(
            toJSON<SmartHomeResponseModel>(data),
            dispatch,
        ),
    );

    processLiveEvent(
        LiveDataEventTypes.HEATING_COOLING_TELEMETRIES,
        ({ data }) =>
            processHeatingCoolingLiveData(
                toJSON<HvacResponseModel>(data),
                dispatch,
            ),
    );

    processLiveEvent(LiveDataEventTypes.WALLBOX_TELEMETRIES, ({ data }) =>
        processWallboxLiveData(toJSON<WallBoxResponseModel>(data), dispatch),
    );

    // there are no SSE events for electric car, so none are added here

    processLiveEvent(LiveDataEventTypes.EVENTS, ({ data }) =>
        processAlarmsLiveData(
            toJSON<NotificationsResponseModel>(data),
            dispatch,
        ),
    );

    processLiveEvent(LiveDataEventTypes.GATEWAY_STATUSES, ({ data }) =>
        processGatewayLiveData(toJSON<EventsResponseModel>(data), dispatch),
    );

    return sseSource;
};

export const disconnectSSE = (): void => {
    if (!sseSource) {
        return;
    }

    sseSource.close();
    sseSource = undefined;
};

export const handleWebSocketData = (
    data: WebSocketData[],
    dispatch: Dispatch<any>,
) => {
    debug.log('📡📡📡 WebSocket message received:', data);

    // The expected data in the websocket message
    // is an the form of an Array.
    // Anything other than an Array is just discarded.
    if (!Array.isArray(data) || !data.length) {
        return;
    }

    let result;

    for (const item of data) {
        const topic: LiveDataEventTypes | undefined = item.topic;

        switch (topic) {
            case LiveDataEventTypes.PVBATTERY_TELEMETRIES:
                result = processSolarPanelLiveData(item, dispatch);
                break;
            case LiveDataEventTypes.GCP_TELEMETRIES:
                result = processMeterLiveData(item, dispatch);
                break;
            case LiveDataEventTypes.SMARTHOME_TELEMETRIES:
                result = processSmartHomeLiveData(
                    item as unknown as SmartHomeResponseModel,
                    dispatch,
                );
                break;
            case LiveDataEventTypes.HEATING_COOLING_TELEMETRIES:
                result = processHeatingCoolingLiveData(item, dispatch);
                break;
            case LiveDataEventTypes.WALLBOX_TELEMETRIES:
                result = processWallboxLiveData(item, dispatch);
                break;
            case LiveDataEventTypes.ELECTRIC_CAR_TELEMETRIES:
                result = processElectricCarLiveData(item, dispatch);
                break;
            case LiveDataEventTypes.EVENTS:
                result = processAlarmsLiveData(
                    item as NotificationsResponseModel,
                    dispatch,
                );
                break;
            case LiveDataEventTypes.GATEWAY_STATUSES:
                result = processGatewayLiveData(item, dispatch);
                break;
            default:
                result = undefined;
                break;
        }
    }

    return result;
};

export const connectWebSocket = (
    token: string,
    dispatch: Dispatch,
): W3CWebSocket | void => {
    if (window.Cypress) {
        return;
    }

    try {
        if (!webSocket) {
            webSocket = new W3CWebSocket(process.env.WEBSOCKET_URL_DEFAULT, [
                'x-api-key',
                toBase64(token).replace(isAdminPortal() ? '==' : '', ''),
            ]);
        }
    } catch (e) {
        handleError(e, 'Failed connecting to WebSocket:');
    }

    if (!webSocket) {
        return webSocket;
    }

    webSocket.onopen = () => debug.log('🎉🎉🎉 Connected to WebSocket! 🎉🎉🎉');

    webSocket.onclose = () => {
        debug.log('💀💀💀 Disconnected from WebSocket! 💀💀💀');

        !!webSocket && connectWebSocket(token, dispatch);
    };

    webSocket.onmessage = (message: IMessageEvent) => {
        if (typeof message.data !== 'string') {
            return;
        }

        const data: WebSocketData[] = JSON.parse(message.data);

        return handleWebSocketData(data, dispatch);
    };

    return webSocket;
};

export const disconnectWebSocket = (): void => {
    if (!webSocket) {
        return;
    }

    webSocket.close();
    webSocket = undefined;
};

export const connectLiveData =
    () =>
    (dispatch: Dispatch<any>): Array<EventSource | W3CWebSocket | void> => {
        const accessToken: string = getAccessToken();

        return [
            sseSource || connectSSE(accessToken, dispatch),
            webSocket || connectWebSocket(accessToken, dispatch),
        ];
    };

export const disconnectLiveData = (): void => {
    disconnectSSE();
    disconnectWebSocket();
};

export const reconnectLiveData = (dispatch: Dispatch<any>): void => {
    disconnectLiveData();
    dispatch(connectLiveData());
};

export const connectCypressLiveData =
    () =>
    (dispatch: Dispatch<any>): void => {
        if (!window.Cypress) {
            return;
        }

        const socket = io('ws://localhost:3000', {
            query: {
                token: getAccessToken(),
                sseURL: process.env.SSE_URL_DEFAULT,
                socketURL: process.env.WEBSOCKET_URL_DEFAULT,
            },
        });

        socket.on('message', (message) => {
            if (typeof message.data !== 'string') {
                return;
            }

            let data: WebSocketData[] = JSON.parse(message.data);

            if (typeof data === 'string') {
                const parsed = JSON.parse(data);

                data = parsed
                    ? [
                          {
                              ...parsed,
                              topic: message.type,
                          },
                      ]
                    : [];
            }

            return handleWebSocketData(data, dispatch);
        });
    };
