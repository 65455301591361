export const enum LiveDataEventTypes {
    // The values used here are (and have to be) identical to the names which are used in the backend, do not change these.
    EVENTS = 'events',
    NOTIFICATIONS = 'notifications',
    GCP_TELEMETRIES = 'gcp-telemetries',
    DHW_TELEMETRIES = 'dhw-telemetries',
    GATEWAY_STATUSES = 'gateway-statuses',
    WALLBOX_TELEMETRIES = 'wallbox-telemetries',
    ELECTRIC_CAR_TELEMETRIES = 'electric-car-telemetries',
    PVBATTERY_TELEMETRIES = 'pvbattery-telemetries',
    SMARTHOME_TELEMETRIES = 'smarthome-telemetries',
    COMPRESSOR_TELEMETRIES = 'compressor-telemetries',
    HEATING_COOLING_TELEMETRIES = 'hvac-telemetries',
}

export const enum LiveDataActionTypes {
    UNUSABLE_EVENT = 'UNUSABLE_EVENT',
    GCP_TELEMETRIES = 'GCP_TELEMETRIES',
    EMOBILITY_STATUS = 'EMOBILITY_STATUS',
    GATEWAY_STATUSES = 'GATEWAY_STATUSES',
    SMART_PLUGS_EVENT = 'SMART_PLUGS_EVENT',
    UPDATE_SMART_PLUG = 'UPDATE_SMART_PLUG',
    EVENT_NOTIFICATION = 'EVENT_NOTIFICATION',
    SMART_LIGHTS_EVENT = 'SMART_LIGHTS_EVENT',
    UPDATE_SMART_LIGHT = 'UPDATE_SMART_LIGHT',
    RESET_SMART_DEVICES = 'RESET_SMART_DEVICES',
    PVBATTERY_TELEMETRIES = 'PVBATTERY_TELEMETRIES',
    HEATING_COOLING_EVENT = 'HEATING_COOLING_EVENT',
    WALLBOX_TELEMETRIES = 'WALLBOX_TELEMETRIES',
    ELECTRIC_CAR_TELEMETRIES = 'ELECTRIC_CAR_TELEMETRIES',
    SMART_PLUGS_TELEMETRIES = 'SMART_PLUGS_TELEMETRIES',
    UPDATE_HEATING_COOLING = 'UPDATE_HEATING_COOLING',
    HEATING_COOLING_TELEMETRIES = 'HEATING_COOLING_TELEMETRIES',
}

export const enum TelemetryTypes {
    GCP = 'gcp',
    DHW = 'dhw',
    WALLBOX = 'wallbox',
    ELECTRIC_CAR = 'electricCar',
    PV_BATTERY = 'pvbattery',
    COMPRESSOR = 'compressor',
    NOTIFICATIONS = 'notifications',
    GATEWAY_STATUSES = 'gatewayStatuses',
    EMOBILITY = 'emobility',
}

export enum FirmwareOngoingUpdateStatuses {
    FIRMWARE_UPDATE_ACCEPTED = 'FIRMWARE_UPDATE_ACCEPTED',
    REBOOT_ACCEPTED = 'REBOOT_ACCEPTED',
    REBOOT_COMPLETED = 'REBOOT_COMPLETED',
    UPDATE_DOWNLOAD_STARTED = 'UPDATE_DOWNLOAD_STARTED',
    FIRMWARE_UPDATE_DOWNLOADED = 'FIRMWARE_UPDATE_DOWNLOADED',
    FIRMWARE_UPDATE_INSTALLED = 'FIRMWARE_UPDATE_INSTALLED',
    FINISHED_FAILED = 'FINISHED_FAILED',
    FINISHED_SUCCESS = 'FINISHED_SUCCESS',
}

export enum MessageTypeEnumExtended {
    EVSE_FIRMWARE = 'Evse-Firmware',
    EVSE_SECURITY_NOTIFICATION = 'Evse-Security-Notification',
}
