import { Dispatch } from 'redux';

import {
    GCPApi,
    PvBatteryApi,
    ResponseError,
    EfficiencyCheckV2ResponseModel,
    PvBatteryDonutHistoryV2ResponseModelTimelineEnum,
    GasHistoryV2ResponseModel as GasModel,
    ForecastValuesResponseModel as ForecastModel,
    PowerHistoryV2ResponseModel as PowerModel,
    EnergyHistoryV2ResponseModel as EnergyModel,
    EfficiencyCheckV2ResponseModel as EfficiencyCheckModelV2,
    SolarCloudHistoryV2ResponseModel as SolarCloudModel,
    PvBatteryDonutHistoryV2ResponseModel as DonutModel,
    EvChargingHistoryAggregatedResponseModel as WallboxModel,
    GridConnectionPointHistoryAggregationResponseModel as GCPModel,
} from '@swagger-http';

import { Scope, GraphTypes } from '@tools/enums';
import { Nullable, NumberOrNull } from '@tools/types';
import { setHistoricalData, getHistoricalEmobilityData } from '@store/actions';
import {
    IS_NOT_DEV,
    AGGREGATION_THRESHOLD,
    AGGREGATION_TIME_FORMAT,
} from '@tools/constants';
import {
    GraphType,
    ExtendedGraphType,
    AggregatedDataAction,
} from '@store/types';
import {
    GcpTelemetry,
    HistoricalData,
    DonutsDataModel,
    HistoricalDataBranch,
    AggregatedDataUserState,
    AggregatedDataProperties,
    HistoricalDonutDataModel,
    HistoricalAggregationData,
    AggregatedDataEnergyState,
    HistoricalDataEnergyFlowBranch,
} from '@store/types';
import {
    Res,
    SolarCloudStatus,
    HistoricalDataTypes,
    HistoricalResolution,
    EnergyFlowActionTypes,
    AggregatedDataActionTypes,
    HistoricalDataActionTypes,
    ExtendedHistoricalResolution,
} from '@store/enums';
import {
    delay,
    Moment,
    isToday,
    handleError,
    getResolution,
    checkForScopes,
    getModifiedDate,
    getRequestDates,
    mapToLocalTimeZone,
    getLastReadingDate,
    extractAggregatedData,
    normalizeForecastData,
    getComparisonInterval,
    createRequestConfiguration,
    checkProductActivationForPeriod,
} from '@tools/utils';

const emptyResponse = [[], []];
// prettier-ignore
const { Currently, _7days, _30days, _365days } = PvBatteryDonutHistoryV2ResponseModelTimelineEnum;

export const clearDataBeforeRegistrationDate = (
    data: any[],
    date: string,
    res: HistoricalResolution,
) => {
    const isYear = res === HistoricalResolution.YEAR;
    const granularity = isYear ? 'month' : 'day';
    const start = Moment(date).startOf(granularity).utc();

    return data.map((item) => {
        if (Moment(item.timestamp).utc().isSameOrAfter(start, granularity)) {
            return item;
        }

        const { timeline, timestamp, ...rest } = item;

        for (const key in rest) {
            rest[key] = null;
        }

        return {
            timeline,
            timestamp,
            ...rest,
        };
    });
};

export const setAggregatedDataLoading = (
    res: HistoricalResolution,
    type: ExtendedGraphType,
): AggregatedDataAction => ({
    type: AggregatedDataActionTypes.LOADING,
    payload: { res, type },
});

export const setAggregatedDataError = (
    res: HistoricalResolution,
    type: ExtendedGraphType,
): AggregatedDataAction => ({
    type: AggregatedDataActionTypes.ERROR,
    payload: { res, type },
});

export const setSliderTouched = (sliderTouched: boolean) => ({
    type: AggregatedDataActionTypes.SET_SLIDER_TOUCHED,
    payload: {
        sliderTouched,
    },
});

export const setSliderValue = (value: NumberOrNull) => ({
    type: AggregatedDataActionTypes.SET_SLIDER_VALUE,
    payload: {
        value,
    },
});

export const setSliderScrollPosition = (scrollPosition: number) => ({
    type: AggregatedDataActionTypes.SET_SLIDER_SCROLL_POSITION,
    payload: {
        scrollPosition,
    },
});

/*
    This function takes care of splitting the response from the backend in two arrays.
    The response from the backend is expected to contain data for two periods (current
    and previous). This function splits them and returns the new array containing two arrays.
*/
export const splitInTwo = <T extends Record<string, any>>(
    data: T[],
    isForecast = false,
): [T[], T[]] => {
    if (!isForecast) {
        return data.reduce(
            (result: [T[], T[]], item: T) => {
                result[item.timeline === Currently ? 0 : 1].push(item);

                return result;
            },
            [[], []],
        );
    }

    const start = 0;
    const { length } = data;
    const middle = Math.floor(length / 2);

    /**
     * Forecast response contains unique values which do not match any
     * of the rest of the endpoints. This is the reason we need to adjust
     * the split function by adding or subtracting a value from the
     * middle of the array in order to achieve the required result.
     */
    const adjustmentStart = !isForecast ? 1 : start;
    const adjustmentEnd = !isForecast ? 0 : start;

    return [
        data.slice(start, middle + adjustmentStart),
        data.slice(middle + adjustmentEnd, length),
    ];
};

export const getPvForecast = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
): Promise<ForecastModel[][]> => {
    if (!checkForScopes([Scope.PVFORECAST_READ])) {
        return emptyResponse;
    }

    const shouldRequestForecast = IS_NOT_DEV
        ? isToday(date, res) && res === HistoricalResolution.DAY1HOUR
        : res === HistoricalResolution.DAY1HOUR ||
          res === HistoricalResolution.WEEK;

    if (!shouldRequestForecast) {
        return emptyResponse;
    }

    const resolution: string = getResolution(res);
    const { startDate, endDate } = getRequestDates(date, res, true);

    const [prevForecastData, forecastData] = await new PvBatteryApi(
        createRequestConfiguration(),
    )
        .pvBatteryGetForecastAggregated1({
            fromDate: startDate,
            toDate: endDate,
            interval: resolution,
        })
        .then((data: ForecastModel[]) => normalizeForecastData(data))
        .then((data: ForecastModel[]) => {
            if (res === HistoricalResolution.WEEK) {
                return data.map((item) => {
                    const { timestamp, ...rest }: any = item;

                    return {
                        ...rest,
                        timestamp: timestamp.replace(
                            /(.*T)(\d+)(:.*)/,
                            // prettier-ignore
                            (_: string, p1: string, __: string, p2: string) => `${p1}00${p2}`,
                        ),
                    };
                });
            }

            return data;
        })
        .then((data: ForecastModel[]) => mapToLocalTimeZone(data, res))
        .then((data: ForecastModel[]) =>
            /**
             * The response from the PVBattery historical endpoint contains a PVpower property
             * That's why we rename the property in the forecast response to avoid overwrites.
             */
            data.map((item: any) => {
                const { values, ...rest } = item;

                return {
                    ...rest,
                    values: {
                        PVpowerOutTotal: values?.PVpower,
                        PVenergyOutTotal: values?.PVenergyOut,
                    },
                };
            }),
        )
        .then((data: ForecastModel[]) =>
            splitInTwo(
                clearDataBeforeRegistrationDate(
                    data,
                    userState.registrationDate,
                    res,
                ),
                true,
            ),
        )
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting pv forecast data:');

            return emptyResponse;
        });

    return [prevForecastData, forecastData];
};

export const fetchPvEfficiency = async (): Promise<
    EfficiencyCheckV2ResponseModel[]
> => {
    const start = Moment().startOf('month');
    const endDate = start;
    const startDate = start.clone().subtract(2, 'months').subtract(0, 'second');

    return await new PvBatteryApi(createRequestConfiguration())
        .pvBatteryGetEfficiencyCheck1({
            fromDate: startDate.format(AGGREGATION_TIME_FORMAT),
            toDate: endDate.format(AGGREGATION_TIME_FORMAT),
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting pv forecast data:');

            return [];
        });
};

export const getPvEfficiencySource = (
    data: EfficiencyCheckModelV2[],
): EfficiencyCheckModelV2 => {
    // If the value for the last month is null
    // we need to use the month before
    const last = data[data.length - 1];
    const prev = data[data.length - 2];
    const hasValueForLastMonth = last.value;

    return hasValueForLastMonth ? last : prev;
};

export const getPvEfficiency = (
    data: EfficiencyCheckModelV2[],
): HistoricalData['pvEfficiency'] => {
    if (!data || !Array.isArray(data) || !data.length) {
        return {
            value: null,
            timestamp: Moment().toISOString(),
        };
    }

    const source = getPvEfficiencySource(data);
    const value = source.value;
    const timestamp = source.from;

    return {
        value: typeof value === 'number' ? value * 100 : null,
        timestamp: timestamp as unknown as string,
    };
};

// Get this from the forecast when implemented in the BE
// And if enabled in the FE
export const getSunData = (date: Date) => {
    const sunrise: string = Moment(date)
        .set('hours', 8)
        .set('minutes', 55)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString();

    const sunset: string = Moment(date)
        .set('hours', 17)
        .set('minutes', 35)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString();

    return { sunrise, sunset };
};

export const getAggregatedData = async (
    date: Date,
    res: HistoricalResolution,
    dispatch: Dispatch,
    historicalData: HistoricalDataBranch,
    energyState: AggregatedDataEnergyState,
    userState: AggregatedDataUserState,
    wallboxInstalled: boolean,
    type: GraphType,
): Promise<AggregatedDataAction> => {
    const { timezone } = userState;
    const {
        hasInverter,
        hasGasMeter,
        hasUkSmartMeter,
        solarCloudEndDate,
        solarCloudStartDate,
        hasElectricityMeter,
    } = energyState;

    const dates = getRequestDates(date, res, true);
    const { startingDate, resolutionEndDate, resolutionStartDate } = dates;

    const shouldGetPower = (
        [GraphTypes.OVERVIEW_POWER] as ReadonlyArray<GraphType>
    ).includes(type);

    // TODO: Consider removing the solar cloud exceptions when the BE handles the 500 on /energy
    // https://jira.eon.com/browse/HS-9627 && https://jira.eon.com/browse/HS-9625
    const shouldGetEnergy =
        type === GraphTypes.EMOBILITY ||
        type === GraphTypes.SOLAR_CLOUD ||
        type === GraphTypes.SOLAR_CLOUD_BALANCE
            ? false
            : !(
                  [GraphTypes.OVERVIEW_POWER] as ReadonlyArray<GraphType>
              ).includes(type);

    const solarCloudStatus = checkProductActivationForPeriod(
        {
            solarCloudEndDate,
            solarCloudStartDate,
        },
        resolutionStartDate,
        resolutionEndDate,
    );

    const shouldGetPvForecast = (
        [GraphTypes.INVERTER, 'all'] as ReadonlyArray<GraphType>
    ).includes(type);

    const historicalDataItem = historicalData[res].find(
        (item: any) =>
            item.data.type === type && item.startDate === startingDate,
    );

    dispatch(setAggregatedDataLoading(res, type));

    if (historicalDataItem) {
        const payload = historicalDataItem.data;
        const itemDate = Moment(historicalDataItem.timestamp);

        const expirationDate = isToday(date, res)
            ? itemDate.add(
                  AGGREGATION_THRESHOLD.AMOUNT,
                  AGGREGATION_THRESHOLD.UNIT,
              )
            : itemDate.endOf('day');

        const hasCachedPvForecast = shouldGetPvForecast
            ? payload.data?.forecast?.[res]?.data?.length > 0
            : true;

        const hasCachedPower = shouldGetPower
            ? payload.data?.inverterPower?.[res]?.data?.length > 0
            : true;

        // Force full re-render in the graphs
        await delay(800);

        if (
            hasCachedPower &&
            hasCachedPvForecast &&
            Moment().isBefore(expirationDate)
        ) {
            payload.type = type;

            dispatch({
                payload,
                type: AggregatedDataActionTypes.FETCH,
            });

            return Promise.resolve({
                payload,
                type: AggregatedDataActionTypes.FETCH,
            });
        }
    }

    const v2Data: AggregatedDataProperties = await getAggregatedDataV2(
        date,
        res,
        userState,
        !!hasGasMeter,
        shouldGetPower,
        shouldGetEnergy,
        solarCloudStatus !== SolarCloudStatus.INACTIVE,
    );

    const emobilityData = await getHistoricalEmobilityData(
        wallboxInstalled,
        date,
        res,
        userState,
        dispatch,
    );

    const [prevForecastData, forecastData] = !shouldGetPvForecast
        ? emptyResponse
        : hasInverter
        ? await getPvForecast(date, res, userState)
        : emptyResponse;

    const { prevDay, ...currentV2Data } = v2Data;
    // prettier-ignore
    const { prevDay: prevEmobilityData, ...currentEmobilityData } = emobilityData as any;

    const normalizedForecastData = forecastData.map(({ values, ...rest }) => ({
        ...values,
        ...rest,
    }));

    const normalizedPrevForecastData = prevForecastData
        .map(({ values, ...rest }) => ({ ...values, ...rest }))
        .map((item) => ({
            ...item,
            timestamp: getModifiedDate(
                res,
                item.timestamp as unknown as string,
                Moment(date).date(),
                true,
            ),
        }));

    const currentV2EnergyForecast = extractAggregatedData(
        date,
        normalizedForecastData,
        res,
        'PVenergyOutTotal' as any,
        timezone,
        false,
        currentV2Data?.generation?.[res]?.data,
    );

    const currentV2PowerForecast = extractAggregatedData(
        date,
        normalizedForecastData,
        res,
        'PVpowerOutTotal' as any,
        timezone,
        false,
        currentV2Data?.generation?.[res]?.data,
    );

    const prevV2EnergyForecast = extractAggregatedData(
        date,
        normalizedPrevForecastData,
        res,
        'PVenergyOutTotal' as any,
        timezone,
        false,
        prevDay?.prevGeneration?.[res]?.data,
    );

    const prevV2PowerForecast = extractAggregatedData(
        date,
        normalizedPrevForecastData,
        res,
        'PVpowerOutTotal' as any,
        timezone,
        false,
        prevDay?.prevGeneration?.[res]?.data,
    );

    const data: AggregatedDataProperties = {
        forecast: currentV2EnergyForecast,
        nightTariff: {
            [res]: getSunData(date),
        },
        PVpowerOutTotal: currentV2PowerForecast,
        PVenergyOutTotal: currentV2EnergyForecast,
        solarCloudStatus: checkProductActivationForPeriod(
            {
                solarCloudEndDate,
                solarCloudStartDate,
            },
            resolutionStartDate,
            resolutionEndDate,
        ),

        ...currentV2Data,
        ...currentEmobilityData,
        prevDay: {
            prevForecast: prevV2EnergyForecast,
            prevPVpowerOutTotal: prevV2PowerForecast,
            prevPVenergyOutTotal: prevV2EnergyForecast,
            ...prevDay,
            ...prevEmobilityData,
        },
    };

    let lastReadingKey: keyof AggregatedDataProperties = 'generation';

    if (hasUkSmartMeter) {
        lastReadingKey =
            hasGasMeter && hasElectricityMeter
                ? 'fromGridElectricity'
                : hasGasMeter
                ? 'fromGridGas'
                : 'fromGridElectricity';
    }

    const lastReadingSource = v2Data[lastReadingKey];

    const payload = {
        res,
        type,
        lastReading: getLastReadingDate(
            lastReadingSource ? lastReadingSource[res].data : [],
        ),
        data,
    };

    const timestamp = new Date().getTime();
    const action = {
        payload,
        type: AggregatedDataActionTypes.FETCH,
    };

    dispatch(
        setHistoricalData(HistoricalDataActionTypes.SET_AGGREGATED_DATA, {
            type: HistoricalDataTypes.EC,
            data: payload,
            startDate: startingDate,
            timestamp,
            resolution: res,
        }),
    );

    dispatch(action);

    if (v2Data.error) {
        dispatch(setAggregatedDataError(res, type));
    }

    return action;
};

export const aggregateEmobilityData = (
    emobilityData: WallboxModel[],
): Record<string, number> => {
    if (!emobilityData.length) {
        return { wattHour: 0 };
    }

    const firstEv = emobilityData[0].values;
    const lastEv = emobilityData[emobilityData.length - 1].values;

    // prettier-ignore
    const wattHour = (lastEv!.hemsCustomValuesEnergyHome2EVTotal! || 0) - (firstEv!.hemsCustomValuesEnergyHome2EVTotal! || 0);

    return { wattHour };
};

export const aggregateGCPData = (
    data: HistoricalAggregationData,
): GcpTelemetry => {
    const {
        generation: { toGrid },
        consumption: { fromGrid },
    } = data;

    return {
        loading: false,
        home2grid: toGrid,
        grid2home: fromGrid,
    };
};

export const setHistoricalDataForMonth = (
    dispatch: Dispatch,
    data: any,
    isPromise: boolean = false,
): any => {
    const { donuts, energyFlow, pvEfficiency } = data;

    dispatch(
        setHistoricalData(
            HistoricalDataActionTypes.SET_AGGREGATED_DATA_FOR_DONUTS,
            donuts,
        ),
    );

    dispatch(
        setHistoricalData(
            HistoricalDataActionTypes.SET_AGGREGATED_DATA_FOR_ENERGY_FLOW,
            energyFlow,
        ),
    );

    dispatch(
        setHistoricalData(
            HistoricalDataActionTypes.SET_PV_EFFICIENCY,
            pvEfficiency,
        ),
    );

    if (energyFlow?.data?.today) {
        const { requestEndDate, requestStartDate, shouldDisable30Days } =
            energyFlow.data.today;

        dispatch({
            type: EnergyFlowActionTypes.SET_REQUEST_DATES,
            payload: {
                requestStartDate,
                requestEndDate,
                shouldDisable30Days,
            },
        });
    }

    const result = {
        donutsData: donuts.data,
        energyFlowData: energyFlow.data,
    };

    return isPromise ? Promise.resolve(result) : result;
};

export const getAggregatedDataForMonth = async (
    date: Date,
    dispatch: Dispatch,
    historicalData: HistoricalData,
    energyState: AggregatedDataEnergyState,
    userState: AggregatedDataUserState,
): Promise<Record<string, any>> => {
    // prettier-ignore
    const { hasInverter, hasSmartMeter, hasUkSmartMeter, hasPvEfficiency } = energyState;

    const res = ExtendedHistoricalResolution.THIRTY_DAYS;
    const dates = getRequestDates(date, res);
    const smartMeterInstalled = !!hasSmartMeter;
    // prettier-ignore
    const hasHistoricalDataItem = historicalData.donuts.startDate === dates.startDate;

    if (hasHistoricalDataItem) {
        const itemDate = Moment(historicalData.donuts.timestamp);
        const expirationDate = itemDate.add(
            AGGREGATION_THRESHOLD.AMOUNT,
            AGGREGATION_THRESHOLD.UNIT,
        );

        if (Moment().isBefore(expirationDate)) {
            return setHistoricalDataForMonth(dispatch, historicalData, true);
        }
    }

    const efficiencyData = hasPvEfficiency ? await fetchPvEfficiency() : [];

    const emptyV2Data: DonutsDataModel = {
        donuts: {
            day: null,
            week: null,
            month: null,
            year: null,
        },
        energyFlow: {
            today: null,
            yesterday: null,
            '30d': null,
        },
    };

    const v2Data = !hasUkSmartMeter
        ? await getDonutsData(
              hasInverter,
              smartMeterInstalled,
              userState,
              emptyV2Data,
          )
        : emptyV2Data;

    const timestamp = new Date().getTime();

    return setHistoricalDataForMonth(dispatch, {
        donuts: {
            data: v2Data.donuts,
            type: HistoricalDataTypes.DONUTS,
            startDate: dates.startDate,
            timestamp,
            shouldRender: true,
        },
        energyFlow: {
            data: v2Data.energyFlow,
            type: HistoricalDataTypes.EF,
            startDate: dates.startDate,
            timestamp,
        },
        pvEfficiency: getPvEfficiency(efficiencyData),
    });
};

export const getAllAggregatedData = async (
    dispatch: Dispatch,
    historicalData: HistoricalData,
    energyState: AggregatedDataEnergyState,
    userState: AggregatedDataUserState,
    wallboxInstalled: boolean,
    date: Date = new Date(),
): Promise<void> => {
    const { hasInverter, hasUkSmartMeter, hasSmartMeter } = energyState;
    const res = HistoricalResolution.DAY1HOUR;
    const isSmartMeter = !!hasUkSmartMeter || hasSmartMeter;

    if (!isSmartMeter && !hasInverter) {
        return;
    }

    await getAggregatedData(
        isSmartMeter ? Moment(date).subtract(1, 'day').toDate() : date,
        res,
        dispatch,
        historicalData.aggregatedData,
        energyState,
        userState,
        wallboxInstalled,
        'all',
    ).then((action: AggregatedDataAction) => {
        dispatch({
            type: AggregatedDataActionTypes.FETCH,
            payload: {
                ...action.payload,
                type: 'all',
            },
        });

        if (isSmartMeter && !hasUkSmartMeter) {
            getAggregatedData(
                date,
                res,
                dispatch,
                historicalData.aggregatedData,
                { ...energyState, gcp: false, hasGCP: false },
                userState,
                wallboxInstalled,
                GraphTypes.INVERTER,
            ).then((a: AggregatedDataAction) => {
                dispatch({
                    type: AggregatedDataActionTypes.FETCH,
                    payload: {
                        ...a.payload,
                        type: GraphTypes.INVERTER,
                    },
                });
            });
        }
    });

    if (!hasInverter) {
        return;
    }

    await getAggregatedDataForMonth(
        date,
        dispatch,
        historicalData,
        energyState,
        userState,
    );
};

export const setAggregatedDataDate = (payload: Date) => ({
    type: AggregatedDataActionTypes.SET_DATE,
    payload,
});

export const setAggregatedDataRes = (payload: HistoricalResolution) => ({
    type: AggregatedDataActionTypes.SET_RESOLUTION,
    payload,
});

export const downloadAggregatedData = async (
    dates: Record<'startDate' | 'endDate', string>,
    res: Res,
    userState: AggregatedDataUserState,
): Promise<AggregatedDataProperties> =>
    getAggregatedDataV2(
        new Date(),
        res as HistoricalResolution,
        userState,
        false,
        false,
        true,
        false,
        dates,
    );

export const getAggregatedDataV2 = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
    shouldFetchGas: boolean,
    shouldFetchPower: boolean,
    shouldFetchEnergy: boolean,
    shouldFetchSolarCloud: boolean,
    dates?: Record<'startDate' | 'endDate', string>,
): Promise<AggregatedDataProperties> => {
    const config = createRequestConfiguration();
    const pvbAPI = new PvBatteryApi(config);
    const resolution = getResolution(res);
    const comparisonInterval = getComparisonInterval(res);
    // prettier-ignore
    const { startDate, endDate } = dates || getRequestDates(date, res, false);
    const { timezone, registrationDate } = userState;
    const args = {
        fromDate: startDate,
        toDate: endDate,
        interval: resolution,
        comparisonInterval,
    };

    const energy =
        shouldFetchEnergy && checkForScopes([Scope.ENERGYDEVICES_PVB_READ])
            ? pvbAPI.pvBatteryGetEnergyAggregatedV2(args)
            : Promise.resolve([]);

    const power =
        shouldFetchPower && checkForScopes([Scope.ENERGYDEVICES_PVB_READ])
            ? pvbAPI.pvBatteryGetPowerAggregatedV2(args)
            : Promise.resolve([]);

    const solarCloud =
        shouldFetchSolarCloud && checkForScopes([Scope.ENERGYDEVICES_PVB_READ])
            ? pvbAPI.pvBatteryGetSolarCloudBalanceHistoryV2(args)
            : Promise.resolve([]);

    const gas =
        shouldFetchGas && checkForScopes([Scope.ENERGYDEVICES_GAS_READ])
            ? pvbAPI.pvBatteryGetGasAggregatedV2(args)
            : Promise.resolve([]);

    return Promise.all([energy, power, solarCloud, gas])
        .then((responses) => {
            const data = responses.map((r) =>
                splitInTwo(
                    clearDataBeforeRegistrationDate(
                        [...r],
                        registrationDate,
                        res,
                    ),
                ).map((item) => mapToLocalTimeZone(item, res)),
            );

            const [currentPower, prevPower]: PowerModel[][] = data[1];
            const [currentEnergy, prevEnergy]: EnergyModel[][] = data[0];
            const [currentGasData, prevGasData]: GasModel[][] = data[3];
            const [currentSolarCloud, prevSolarCloud]: SolarCloudModel[][] =
                data[2];

            const currentGeneration = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'generation',
                timezone,
            );

            const currentConsumptionFromPv = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'consumptionFromPv',
                timezone,
            );

            const currentPvToBattery = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'pvToBattery',
                timezone,
            );

            const currentToBattery = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'toBattery',
                timezone,
            );

            const currentFromBattery = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'fromBattery',
                timezone,
            );

            const currentBatterySoC = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'soc',
                timezone,
            );

            const currentToGrid = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'pvToGrid',
                timezone,
            );

            const currentFromGrid = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'fromGrid',
                timezone,
            );

            const currentBalance = extractAggregatedData(
                date,
                currentSolarCloud,
                res,
                'solarCloudBalanceDelta',
                timezone,
            );

            const currentEnergyConsumption = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'consumption',
                timezone,
            );

            const currentSelfConsumption = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'selfConsumption',
                timezone,
            );

            const currentInverterPower = extractAggregatedData(
                date,
                currentPower,
                res,
                'inverter',
                timezone,
            );

            const currentSelfSufficiency = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'selfSufficiency',
                timezone,
            );

            const prevGeneration = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'generation',
                timezone,
            );

            const prevPvToBattery = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'pvToBattery',
                timezone,
            );

            const prevToBattery = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'toBattery',
                timezone,
            );

            const prevFromBattery = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'fromBattery',
                timezone,
            );

            const prevBatterySoC = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'soc',
                timezone,
            );

            const prevToGrid = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'pvToGrid',
                timezone,
            );

            const prevFromGrid = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'fromGrid',
                timezone,
            );

            const prevBalance = extractAggregatedData(
                date,
                prevSolarCloud,
                res,
                'solarCloudBalanceDelta',
                timezone,
            );

            const prevEnergyConsumption = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'consumption',
                timezone,
            );

            const prevSelfConsumption = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'selfConsumption',
                timezone,
            );

            const prevInverterPower = extractAggregatedData(
                date,
                prevPower,
                res,
                'inverter',
                timezone,
            );

            const prevSelfSufficiency = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'selfSufficiency',
                timezone,
            );

            const prevConsumptionFromPv = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'consumptionFromPv',
                timezone,
            );

            return {
                PV2home: currentGeneration,
                PV2homeToBattery: currentPvToBattery,
                PV2homeToGrid: currentToGrid,
                PV2homeToHome: currentConsumptionFromPv,
                balance: currentBalance,
                batteryEnergyBattery2Home: currentFromBattery,
                batteryEnergyHome2Battery: currentPvToBattery,
                batteryEnergyStateOfCharge: currentBatterySoC,
                batteryPower: extractAggregatedData(
                    date,
                    currentPower,
                    res,
                    'battery',
                    timezone,
                ),
                charging: currentToBattery,
                discharging: currentFromBattery,
                emobility: extractAggregatedData(
                    date,
                    currentEnergy,
                    res,
                    'carCharging',
                    timezone,
                ),
                energyConsumption: currentEnergyConsumption,
                energyConsumptionFromBattery: extractAggregatedData(
                    date,
                    currentEnergy,
                    res,
                    'consumptionFromBattery',
                    timezone,
                ),
                energyConsumptionFromGrid: extractAggregatedData(
                    date,
                    currentEnergy,
                    res,
                    'consumptionFromGrid',
                    timezone,
                ),
                energyConsumptionFromPV: currentConsumptionFromPv,
                fromGridElectricity: currentFromGrid,
                fromGridElectricityCosts: extractAggregatedData(
                    date,
                    currentEnergy,
                    res,
                    'fromGridCosts',
                    timezone,
                    true,
                ),
                energyGrid2home: currentFromGrid,
                energyHome2grid: currentToGrid,
                energySelfConsumption: currentSelfConsumption,
                exported: currentToGrid,
                fromGridGas: extractAggregatedData(
                    date,
                    currentGasData,
                    res,
                    'fromGrid',
                    timezone,
                ),
                fromGridGasCosts: extractAggregatedData(
                    date,
                    currentGasData,
                    res,
                    'fromGridCosts',
                    timezone,
                    true,
                ),
                fromSolarCloud: extractAggregatedData(
                    date,
                    currentSolarCloud,
                    res,
                    'fromSolarCloud',
                    timezone,
                ),
                generation: extractAggregatedData(
                    date,
                    currentEnergy,
                    res,
                    'pvGeneration',
                    timezone,
                ),
                gridPower: extractAggregatedData(
                    date,
                    currentPower,
                    res,
                    'grid',
                    timezone,
                ),
                household: currentEnergyConsumption,
                imported: currentFromGrid,
                inverterPower: currentInverterPower,
                nightTariff: {
                    [res]: getSunData(date),
                },
                power: currentInverterPower,
                powerConsumption: extractAggregatedData(
                    date,
                    currentPower,
                    res,
                    'consumption',
                    timezone,
                ),
                powerSelfConsumption: extractAggregatedData(
                    date,
                    currentPower,
                    res,
                    'selfConsumption',
                    timezone,
                ),
                pvbattery: currentGeneration,
                saved: currentToBattery,
                self: currentSelfConsumption,
                selfsufficiency: currentSelfSufficiency,
                selfSufficiency: currentSelfSufficiency,
                solarCloudBalance: currentBalance,
                solarCloudBalanceDelta: currentBalance,
                solarCloudEnabled: extractAggregatedData(
                    date,
                    currentSolarCloud,
                    res,
                    'solarCloudEnabled',
                    timezone,
                ),
                state: currentBatterySoC,
                toSolarCloud: extractAggregatedData(
                    date,
                    currentSolarCloud,
                    res,
                    'toSolarCloud',
                    timezone,
                ),
                prevDay: {
                    prevPV2home: prevGeneration,
                    prevPV2homeToBattery: prevPvToBattery,
                    prevPV2homeToGrid: prevToGrid,
                    prevPV2homeToHome: prevConsumptionFromPv,
                    prevBalance: prevBalance,
                    prevBatteryEnergyBattery2Home: prevFromBattery,
                    prevBatteryEnergyHome2Battery: prevPvToBattery,
                    prevBatteryEnergyStateOfCharge: prevBatterySoC,
                    prevBatteryPower: extractAggregatedData(
                        date,
                        prevPower,
                        res,
                        'battery',
                        timezone,
                    ),
                    prevCharging: prevToBattery,
                    prevDischarging: prevFromBattery,
                    prevEmobility: extractAggregatedData(
                        date,
                        prevEnergy,
                        res,
                        'carCharging',
                        timezone,
                    ),
                    prevEnergyConsumption: prevEnergyConsumption,
                    prevEnergyConsumptionFromBattery: extractAggregatedData(
                        date,
                        prevEnergy,
                        res,
                        'consumptionFromBattery',
                        timezone,
                    ),
                    prevEnergyConsumptionFromGrid: extractAggregatedData(
                        date,
                        prevEnergy,
                        res,
                        'consumptionFromGrid',
                        timezone,
                    ),
                    prevEnergyConsumptionFromPV: prevConsumptionFromPv,
                    prevFromGridElectricity: prevFromGrid,
                    prevFromGridElectricityCosts: extractAggregatedData(
                        date,
                        prevEnergy,
                        res,
                        'fromGridCosts',
                        timezone,
                        true,
                    ),
                    prevEnergyGrid2home: prevFromGrid,
                    prevEnergyHome2grid: prevToGrid,
                    prevEnergySelfConsumption: prevSelfConsumption,
                    prevExported: prevToGrid,
                    prevFromSolarCloud: extractAggregatedData(
                        date,
                        prevSolarCloud,
                        res,
                        'fromSolarCloud',
                        timezone,
                    ),
                    prevFromGridGas: extractAggregatedData(
                        date,
                        prevGasData,
                        res,
                        'fromGrid',
                        timezone,
                    ),
                    prevFromGridGasCosts: extractAggregatedData(
                        date,
                        prevGasData,
                        res,
                        'fromGridCosts',
                        timezone,
                        true,
                    ),
                    prevGeneration: extractAggregatedData(
                        date,
                        prevEnergy,
                        res,
                        'pvGeneration',
                        timezone,
                    ),
                    prevGridPower: extractAggregatedData(
                        date,
                        prevPower,
                        res,
                        'grid',
                        timezone,
                    ),
                    prevHousehold: prevEnergyConsumption,
                    prevImported: prevFromGrid,
                    prevInverterPower: prevInverterPower,
                    prevPower: prevInverterPower,
                    prevPowerConsumption: extractAggregatedData(
                        date,
                        prevPower,
                        res,
                        'consumption',
                        timezone,
                    ),
                    prevPowerSelfConsumption: extractAggregatedData(
                        date,
                        prevPower,
                        res,
                        'selfConsumption',
                        timezone,
                    ),
                    prevPvbattery: prevGeneration,
                    prevSaved: prevToBattery,
                    prevSelf: prevSelfConsumption,
                    prevSelfsufficiency: prevSelfSufficiency,
                    prevSelfSufficiency: prevSelfSufficiency,
                    prevSolarCloudBalance: prevBalance,
                    prevSolarCloudBalanceDelta: prevBalance,
                    prevSolarCloudEnabled: extractAggregatedData(
                        date,
                        prevSolarCloud,
                        res,
                        'solarCloudEnabled',
                        timezone,
                    ),
                    prevState: prevBatterySoC,
                    prevToSolarCloud: extractAggregatedData(
                        date,
                        prevSolarCloud,
                        res,
                        'toSolarCloud',
                        timezone,
                    ),
                },
            };
        })
        .catch(async (error: ResponseError) => {
            await handleError(error, 'Error when getting aggregated data V2:');

            return { error };
        });
};

export const getSingleDonutData = (
    response: DonutModel[],
    timeline: PvBatteryDonutHistoryV2ResponseModelTimelineEnum,
): Nullable<HistoricalDonutDataModel> => {
    const item = response.find(
        (item: DonutModel) => item.timeline === timeline,
    );

    if (!item) {
        return null;
    }

    let { generation, consumption } = item;

    if (!generation) {
        generation = 0;
    }

    if (!consumption) {
        consumption = 0;
    }

    return {
        length: 1,
        timestamp: '',
        shouldDisable: false,
        values: {
            generation: {
                total: generation,
                toHome: item.toHome,
                inBattery: item.toBattery,
                toGrid: item.toGrid,
                fill: generation > consumption ? 0 : consumption - generation,
            },
            consumption: {
                total: consumption,
                fromPv: item.fromPv,
                fromBattery: item.fromBattery,
                fromGrid: item.fromGrid,
                fill: consumption > generation ? 0 : generation - consumption,
            },
        },
    };
};

export const getEnergyFlowRequestDates = (
    timezone: string,
    is30Days: boolean,
    smartMeterInstalled: boolean,
): [string, string] => {
    const subtractFromEnd = smartMeterInstalled ? 1 : 0;

    let subtractFromStart = smartMeterInstalled ? 1 : 0;

    if (is30Days) {
        subtractFromStart = smartMeterInstalled ? 31 : 30;
    }

    return [
        Moment()
            .startOf('day')
            .subtract(subtractFromStart, 'days')
            .format(AGGREGATION_TIME_FORMAT),
        Moment()
            .tz(timezone)
            .subtract(subtractFromEnd, 'days')
            .format(AGGREGATION_TIME_FORMAT),
    ];
};

export const getSingleEnergyFlowData = (
    response: DonutModel[],
    timeline: PvBatteryDonutHistoryV2ResponseModelTimelineEnum,
    registrationDate: string,
    requestStartDate: string,
    requestEndDate: string,
): Nullable<HistoricalDataEnergyFlowBranch> => {
    const item = response.find(
        (item: DonutModel) => item.timeline === timeline,
    );

    if (!item) {
        return null;
    }

    return {
        gcpState: {
            loading: false,
            home2grid: item.toGrid,
            grid2home: item.fromGrid,
        },
        emobilityState: {
            wattHour: item.carCharging,
        },
        energyState: {
            error: false,
            loading: false,
            battery2home: item.fromBattery,
            home2battery: item.toBattery,
            historicalPvGeneration: item.generation,
            historicalConsumption: item.consumption,
            historicalSelfSufficiency: Math.round(item.selfSufficiency || 0),
        },
        requestStartDate,
        requestEndDate,
        shouldDisable30Days: Moment(registrationDate).isAfter(
            Moment().subtract(30, 'days'),
        ),
    };
};

const sum = (data: GCPModel[], key: string): number =>
    data.reduce(
        (result: number, item: GCPModel) =>
            result + (item.values?.[key as keyof GCPModel['values']] || 0),
        0,
    );

export const getDonutsData = async (
    hasInverter: boolean,
    hasSmartMeter: boolean,
    userState: AggregatedDataUserState,
    fallback: DonutsDataModel,
): Promise<DonutsDataModel> => {
    const config = createRequestConfiguration();
    const { timezone, registrationDate } = userState;

    const fromDate = !hasSmartMeter
        ? undefined
        : Moment()
              .endOf('day')
              .subtract(31, 'days')
              .format(AGGREGATION_TIME_FORMAT);

    const toDate = !hasSmartMeter
        ? undefined
        : Moment()
              .endOf('day')
              .subtract(1, 'day')
              .format(AGGREGATION_TIME_FORMAT);

    const promise = hasInverter
        ? new PvBatteryApi(config).pvBatteryGetHistoryDonutAggregatedV2({
              toDate,
          })
        : new GCPApi(config)
              .gcpGetHistoryAggregated({
                  fromDate: fromDate || '',
                  toDate: toDate || '',
              })
              .then((response: GCPModel[]) => {
                  const day = response[response.length - 1];
                  const week = response.slice(-7);
                  const month = response.slice(-30);

                  return [
                      {
                          toGrid: day.values?.energyHome2grid,
                          fromGrid: day.values?.energyGrid2home,
                          timeline: Currently,
                      },
                      {
                          toGrid: sum(week, 'energyHome2grid'),
                          fromGrid: sum(week, 'energyGrid2home'),
                          timeline: _7days,
                      },
                      {
                          toGrid: sum(month, 'energyHome2grid'),
                          fromGrid: sum(month, 'energyGrid2home'),
                          timeline: _30days,
                      },
                  ];
              });

    return await promise
        .then((response: DonutModel[]) => {
            const energyFlowToday = getSingleEnergyFlowData(
                response,
                Currently,
                registrationDate,
                ...getEnergyFlowRequestDates(timezone, false, hasSmartMeter),
            );

            return {
                donuts: {
                    day: getSingleDonutData(response, Currently),
                    week: getSingleDonutData(response, _7days),
                    month: getSingleDonutData(response, _30days),
                    year: getSingleDonutData(response, _365days),
                },
                energyFlow: {
                    today: energyFlowToday,
                    yesterday: energyFlowToday,
                    '30d': getSingleEnergyFlowData(
                        response,
                        _30days,
                        registrationDate,
                        ...getEnergyFlowRequestDates(
                            timezone,
                            true,
                            hasSmartMeter,
                        ),
                    ),
                },
            };
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting aggregated donuts data:');

            return fallback;
        });
};
