import { useState, useEffect, useMemo } from 'react';

import {
    PlugAndChargeModel,
    ScheduleChargingModel,
    DynamicPriceChargingModel,
    SolarAssistedChargingModel,
    GenericSmartChargingModelModeEnum,
} from '@swagger-http';

import { TelemetryTypes } from '@store/enums';
import { ChargingModeData } from '@store/types';
import { MarketingTileModel } from '@tools/types';
import { isTheWallboxCharging } from './shared';
import { MarketingTileTypes, Scope } from '@tools/enums';
import { checkForScopes, prepareInitialDate } from '@tools/utils';
import {
    useAppSelector,
    useTelemetries,
    useSiteTimezone,
    useRegistrationDate,
    useHasEmobilityTokenExpired,
    useIsGridXCustomer,
} from '@store/selectors';

const {
    Schedule,
    DynamicPriceCharging,
    GridFriendlyCharging,
    SolarAssistedCharging,
    HomePlugAndCharge,
} = GenericSmartChargingModelModeEnum;

export const useHasWallbox = () =>
    !!useAppSelector((state) => state.emobility.wallboxData.wallbox?.configId);

export const useWallboxConfigId = () =>
    useAppSelector((state) => state.emobility.wallboxData.wallbox?.configId);

export const useWallboxFirmwareUpdateSettings = () =>
    useAppSelector(
        (state) => state.emobility.wallboxData.firmware.updateSettings,
    );

export const useHasWallboxTokenExpired = () => {
    const tokenExpiryDate = useAppSelector(
        (state) =>
            state.emobility.wallboxData.wallbox?.deviceGateway?.tokenExpiryDate,
    );

    return useHasEmobilityTokenExpired(tokenExpiryDate);
};

// TODO EONFEH-13779: consider putting this in emobility reducer and passing isCharging, status, isOnline as params
export const useIsWallboxCharging = () => {
    const [isCharging, setIsCharging] = useState(false);
    const {
        wallbox: { isWallboxCharging, status, isWallboxOnline },
    } = useTelemetries(TelemetryTypes.EMOBILITY);

    useEffect(() => {
        setIsCharging(
            isTheWallboxCharging({
                isWallboxCharging,
                status,
                isWallboxOnline,
            }),
        );
    }, [status, isWallboxOnline, isWallboxCharging]);

    return isCharging;
};

export const useWallboxConnectButton = (
    marketingTiles: MarketingTileModel[],
): boolean => {
    const tile = marketingTiles.find(
        (item) => item.type === MarketingTileTypes.WALLBOX,
    );

    return (
        !!tile &&
        tile.shouldRender &&
        checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxReadingStartDate = () => {
    const timezone = useSiteTimezone();
    const readingStartDate = useAppSelector(
        (state) =>
            state.emobility.wallboxData.wallbox?.deviceConfiguration
                ?.readingStartDate,
    );
    const defaultReadingStartDate = useRegistrationDate();

    return prepareInitialDate(
        readingStartDate || defaultReadingStartDate,
        timezone,
    );
};

export const useWallboxChargingModes = () => {
    const data = useAppSelector(
        (state) => state.emobility.wallboxData.chargingModes.data,
    );
    const permissions = useWallboxChargingModesPermissions();

    return data
        .filter((item) => item.mode !== GridFriendlyCharging)
        .filter((item) => permissions[item.mode]);
};

export const useWallboxSmartSchedulingData = () => {
    const schedulingData = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === Schedule,
        ),
    ) as ChargingModeData<ScheduleChargingModel> | undefined;

    const gridFriendlyData = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === GridFriendlyCharging,
        ),
    ) as ChargingModeData<ScheduleChargingModel> | undefined;

    /**
     * The smart schedule mode can use data from two
     * different sources: GridFriendly mode for UK or
     * the basic Schedule mode.
     * Currently, if we have an active GridFriendly mode,
     * then it will be used as source of data
     */
    const data = gridFriendlyData?.parameters.active
        ? gridFriendlyData
        : schedulingData;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            hasData: !!data?.parameters,
            defaultEnd: data?.parameters.end ?? '1200',
            defaultStart: data?.parameters.start ?? '0000',
        }),
        [data?.parameters],
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxSolarAssistedChargingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === SolarAssistedCharging,
        ),
    ) as ChargingModeData<SolarAssistedChargingModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            pvPeakThreshold: (data?.parameters.pvPeakThreshold || 0.15) * 100,
            nightChargingEnd: data?.parameters?.nightCharging?.end || '2200',
            nightChargingStart:
                data?.parameters?.nightCharging?.start || '1700',
            nightChargingFallback: !!data?.parameters?.nightChargingFallback,
        }),
        [
            data?.parameters.active,
            data?.parameters.pvPeakThreshold,
            data?.parameters?.nightCharging?.end,
            data?.parameters?.nightCharging?.start,
            data?.parameters?.nightChargingFallback,
        ],
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxPlugAndChargeData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === HomePlugAndCharge,
        ),
    ) as ChargingModeData<PlugAndChargeModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            error: data?.error,
        }),
        [data?.error, data?.parameters.active],
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxDynamicPriceChargingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === DynamicPriceCharging,
        ),
    ) as ChargingModeData<DynamicPriceChargingModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            schedulingTimes: data?.parameters.schedulingTimes,
        }),
        [data?.parameters.active, data?.parameters.schedulingTimes],
    );
};

// TODO EONFEH-13779: adjust this for new insights state once its implemented for wallbox & electric car
export const useLastChargingSessionData = () =>
    useAppSelector((state) => state.insights?.emobility?.lastChargingSession);

export const useWallboxChargingModesPermissions = () => {
    const isGridXCustomer = useIsGridXCustomer();
    return {
        [Schedule]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            !isGridXCustomer,
        [SolarAssistedCharging]: checkForScopes([
            Scope.EMOBILITY_SOLAR_CHARGING_READ,
        ]),
        [GridFriendlyCharging]: checkForScopes([
            Scope.EMOBILITY_SMART_CHARGING_READ,
        ]),
        [DynamicPriceCharging]: checkForScopes([
            Scope.EMOBILITY_DYNAMIC_PRICE_CHARGING_READ,
        ]),
        [HomePlugAndCharge]: checkForScopes([
            Scope.EMOBILITY_SMART_CHARGING_READ,
        ]),
    };
};

export const useWallboxChargingModesCount = () => {
    const permissions = useWallboxChargingModesPermissions();
    return Object.keys(permissions).reduce(
        (acc: boolean[], curr: GenericSmartChargingModelModeEnum) => {
            if (
                ![
                    GenericSmartChargingModelModeEnum.GridFriendlyCharging,
                    GenericSmartChargingModelModeEnum.HomePlugAndCharge,
                ].includes(curr) &&
                permissions[curr]
            ) {
                acc.push(true);
            }

            return acc;
        },
        [],
    ).length;
};
